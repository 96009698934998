import React from "react";
import PopInButton from "./PopInButton";
import BookACall from "./BookACall";
import {useInView} from "react-intersection-observer";


interface IProps {
    children?: any;
    title: string;
    subtitle?: string;
    description: string;
    duration: string;
    features: string[];
    packageDetails: string[];
    lastPoint?: string;
}

const Package = (props: IProps) => {
    const packageInView = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    return (
        <>
            <div ref={packageInView.ref} className={"flex h-full flex-col "}>
                <div
                    className={
                        "flex h-full flex-col bg-white  transition-opacity transition-transform duration-1000 p-4 md:p-8 " +
                        (!packageInView.inView && " translate-y-52 opacity-0") +
                        " " +
                        props.duration
                    }
                >

                        {props.children}


                    <h1 className={" font-mono font-extrabold  text-[1.5rem]"}>
                        {props.title}
                    </h1>

                <p className={"pt-4 md:grow"}>{props.subtitle}</p>
                    <ul className={"ml-1 list-inside list-disc  pt-4 text-sm font-bold"}>
                        {props.packageDetails.map((packageDetail, index) => {
                            return (
                                <li className={"pb-2"} key={index}>
                                    {packageDetail}
                                </li>
                            );
                        })}
                    </ul>
                    <div className={"w-full h-20 pt-4  pb-2  lg:w-full "}>
                        <PopInButton
                            href={"mailto:hello@scrumify.at"}
                            type={"mail"}
                            ariaLabel={"Write an email to us"}
                        >
                            Get in touch
                        </PopInButton>
                    </div>

                    <div className={'mt-2 '}>
                        <BookACall className={" text-left lg:text-center text-sm "}/>
                    </div>


                </div>
            </div>

            <div
                className={
                    "flex flex-col transform-translate transform-opacity mb-4  bg-white p-4 pb-8 md:p-8 lg:mb-0 " +
                    props.duration +
                    " " +
                    (!packageInView.inView && "translate-y-96 opacity-0")
                }
            >
                <p className={"mb-4 font-sans font-bold "}>Includes:</p>
                <ul className={"ml-1 grow list-inside list-disc  text-sm"}>
                    {props.features.map((feature, index) => {
                        return (
                            <li className={"pb-2"} key={index}>
                                {feature}
                            </li>
                        );
                    })}
                </ul>
                <p className={"pt-4 text-sm italic "}>{props.lastPoint}</p>
            </div>
        </>
    );
};

export default Package;
